import { Button } from '@/components/Button';
import { ConnectIconName } from '@/components/icons';
import { FcGoogle } from 'react-icons/fc';
import Image from 'next/image';
import Head from 'next/head';
import Link from 'next/link';
import { useEffect } from 'react';
import posthog from 'posthog-js';

export default function Login() {
    useEffect(() => {
        posthog.reset();
    }, []);

    const faviconPath =
        process.env.NEXT_PUBLIC_SITE_URL !== 'https://connect.clidrive.com' ? '/stg_favicon.ico' : '/favicon.ico';

    return (
        <>
            <Head>
                <title>
                    {process.env.NEXT_PUBLIC_SITE_URL !== 'https://connect.clidrive.com' ? 'STG ' : ''}Connect - Login
                </title>
                <link rel="icon" href={faviconPath} />
            </Head>
            <main className="flex h-screen w-full  items-center justify-center bg-gray-300">
                <div className="flex flex-col items-center space-y-10 rounded-lg bg-white px-28 py-36">
                    <div className="flex flex-col items-center justify-center">
                        <Image
                            src="/images/isologo.svg"
                            width={60}
                            height={60}
                            alt=""
                            className={'h-[60px] w-[60px]'}
                        />
                        <ConnectIconName className="h-[100px] w-[250px]" />
                    </div>
                    <div className="space-y-6 text-center">
                        <span className="font-extralight">Accede con tu cuenta de Google</span>
                        <Link href="/api/auth/login">
                            <Button className="m-auto" color="white">
                                <FcGoogle />
                                Iniciar sesión con Google
                            </Button>
                        </Link>
                    </div>
                </div>
            </main>
        </>
    );
}
